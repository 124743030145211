//JS Files
import "./app/bootstrap";
// import './app/core/popper.min';
// import "./app/core/bootstrap.bundle";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

// import './app/plugins/chartjs.min';
// import './app/plugins/Chart.extension';
// import './app/plugins/smooth-scrollbar.min';
import "./app/plugins/perfect-scrollbar.min";
import "./app/plugins/bootstrap-notify";
import "./app/soft-ui-dashboard";

import "../../../vendor/rappasoft/laravel-livewire-tables/resources/imports/laravel-livewire-tables-all.js";

// Custom JS Files, put at the end
// import select2 from "select2";
import select2 from "select2/dist/js/select2.full.min.js";
window.select2 = select2;
select2(window, $);

// import "drawflow";
import Drawflow from "drawflow";
import "drawflow/dist/drawflow.min.css";
window.Drawflow = Drawflow;

import "./app/custom";

//SCSS Files
import "../scss/soft-ui-dashboard.scss";

// Import all CSS files
import "../css/app/nucleo-icons.css";
import "../css/app/nucleo-svg.css";
import "../css/app/question-builder.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "select2/dist/css/select2.css";
import "select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css";
import "../css/app.scss";

// Import all images and fonts
import.meta.glob(["../images/**", "../fonts/**"]);
